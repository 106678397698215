<template>
  <div>
    <LargeModal
      background="#f3f3f6"
      size="large"
      :display="display"
      :padding="'px-6 pt-6'"
      @close="$emit('close')"
    >
      <div class="lg:w-3/5 mx-auto">
        <transition name="slideY" mode="out-in">
          <MerchantTag
            @back="$emit('close')"
            @close="$emit('close')"
            @success="handleSuccess"
            :code="code"
            :name="name"
          />
        </transition>
      </div>
    </LargeModal>
  </div>
</template>
<script>
import { LargeModal } from "@/UI/Modals";
import { Button } from "@/UI/Button";
export default {
  components: {
    LargeModal,
    Button,
    MerchantTag: () => import("./MerchantTag.vue"),
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: "",
      required: false,
    },
    name: {
      type: String,
      default: "",
      required: false,
    },
  },
  methods: {
    handleSuccess() {
      this.$emit("success");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/styles/animation.css";
.option-container {
  display: grid;
  grid-template-columns: 1.8rem 1fr;
  cursor: pointer;
}
.radio {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  /* margin-right: 1rem; */
  border: 1px solid #6956c8;
  display: grid;
  place-items: center;
  cursor: pointer;
  align-self: center;
}
.radio .checked {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  background-color: #6956c8;
}
</style>
