var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "LargeModal",
        {
          attrs: {
            background: "#f3f3f6",
            size: "large",
            display: _vm.display,
            padding: "px-6 pt-6",
          },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "lg:w-3/5 mx-auto" },
            [
              _c(
                "transition",
                { attrs: { name: "slideY", mode: "out-in" } },
                [
                  _c("MerchantTag", {
                    attrs: { code: _vm.code, name: _vm.name },
                    on: {
                      back: function ($event) {
                        return _vm.$emit("close")
                      },
                      close: function ($event) {
                        return _vm.$emit("close")
                      },
                      success: _vm.handleSuccess,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }